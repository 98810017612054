<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" align="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6" class="leftSection">
        <p v-bind:class="mobile ? 'textSection1Mobile' : 'textSection1'" v-html="$t('textSection1')"></p>
        <v-btn class="contactUsBtn"
               rounded
               v-if="!mobile"
               height="65px"
               dark
               v-html="$t('contactUs')"
               @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
               color="#57939E">
        </v-btn>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6" v-bind:class="mobile ? 'rightSectionMobile' : 'rightSection'">
        <v-img
            :src="require('@/assets/conferenceFormation/conference6.png')"
            contain
            v-bind:height="mobile ? 250 : 400"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'DigitalWorkshopComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Digital workshop: aging well with your time in an institute",
    "textSection1": "The digital workshop: aging well with the times aims to fight the digital divide. Our counselor, equipped with 12 digital tablets, supports the elderly in the use of a tablet and the applications that revolve around it. The priority in this activity is to introduce participants to the use of the tablet and the Internet but it also allows to show useful applications for aging well and maintaining autonomy (relaxation, diet, level of activity daily physical ...).",
    "contactUs": "Contact us"
  },
  "fr": {
    "mainTitle": "Atelier numérique : bien vieillir avec son temps en institut",
    "textSection1": "L’atelier numérique : bien vieillir avec son temps a pour objectif de lutter contre la fracture numérique. Notre intervenant, muni de 12 tablettes numériques, accompagne les personnes âgées dans l’utilisation d’une tablette et des applications qui gravitent autour d’elle. <br><br> La priorité dans cette activité est d’initier les participants à l’utilisation de la tablette et d’internet mais il permet également de montrer des applications utiles au bien vieillir et au maintien de l’autonomie (relaxation, alimentation, niveau d’activité physique quotidien…).",
    "contactUs": "Contactez nous"
  }
}
</i18n>

<style scoped>
.leftSection {
  padding-left: 200px;
  text-align: left;
}
@media screen and (max-width: 1500px) {
  .section {
    font-size: 14px;
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 1500px) {
  .section {
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 50px;
  }
}

.rightSection {
  padding-right: 200px;
}

.rightSectionMobile {
  margin-bottom: 40px;
}

.contactUsBtn {
  font-size: 18px;
  text-transform: none;
  width: 500px;
}

.textSection1 {
  padding-right: 100px;
  padding-bottom: 40px;
  color: #808080;
}
.textSection1Mobile {
  font-size: 14px ;
  color: #808080;
}
</style>
